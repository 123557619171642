$theme-colors: (
  'primary': #3498db,
  'info': #3498db,
  'danger': #c0392b,
  'subtitle': #7e7e7e,
  'dark': #272727,
  'warning': #e06806,
  'light': #f8f9fa,
  'secondary': #7e7e7e,
  'success': #1abc9c,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

.font-weight-bold {
  font-weight: 600;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.text-small {
  font-size: 0.85em;
}

.text-smaller {
  font-size: 0.75em;
}

.text-large {
  font-size: 1.15em;
}


.text-extra-large {
  font-size: 1.5em;
}
